.checklists-show {
  &-gallery {
    position: relative;
    cursor: pointer;

    button {
      display: none;
      position: absolute;
      z-index: 999;
      top: 1rem;
      right: 1rem;
    }

    &:hover {
      button {
        display: block;
      }

      img {
        opacity: 0.5;
      }
    }
  }
}
