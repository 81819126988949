/* You can add global styles to this file, and also import other style files */
$colors: (
  primary: #006af8,
  light: #ecf0f7,
  danger: #ea2e49,
  warning: #f57f17,
  success: #21ce99,
  greylight: #767676,
  dark: #000000
);

@import '~angular-calendar/css/angular-calendar.css';

@import '../../../src/styles';
@import './app/public/login/login.component';
@import './app/public/public.component';
@import './app/protected/protected.component';
@import './app/protected/checklists/show/checklists.show.component.scss';
@import '../../../src/app/shared/components/modals/locations/modals.locations.component.scss';