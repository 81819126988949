app-public {
  .ql-editor {
    height: auto;
  }

  .ql-container {
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
}
